<template lang="pug">
section.main.layout-editor
  //- aside.side-menu(v-if="$auth.loggedIn")
  aside.side-menu
    Sidemenu
  main.core
    //- ActiveTeam
    section.section
      slot
  //- aside.side-menu(v-if="$auth.loggedIn")
  //-   main-menu
  //- main.core
  //-   nuxt
</template>

<script setup>
import { useTeamStore } from "~/stores/team";
const teamStore = useTeamStore();
await teamStore.getTeams();
await teamStore.getActiveTeam();
</script>
<style lang="scss">
.layout-editor {
  .section {
    padding: 0;
  }
}
section.main {
  display: flex;
  width: 100%;
  min-height: 100vh;
  .side-menu {
    // flex: 1;
    width: rempx(91);
  }
  .core {
    flex: 1;
  }
}
</style>
